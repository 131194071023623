<template>
  <div class="list">
    <div class="list_detail">
      <div class="detail_a">
        {{guadan?'挂单成功！':"操作成功！"}} <a href="javascript:void(0)" @click="goback">返回</a>
      </div>
      <div class="detail_a" v-if="guadan">
        挂单编号：{{guadanId}}
      </div>
      <div class="detail_b" v-if="!guadan">
        <div>
          <div class="detail_b_1"><a href="javascript:void(0)" v-if="UserId" @click="getVIPUser">查看会员</a></div>
          <div class="detail_b_2" v-if="data.VIPUser.xingming">
            <ul>
              <li>姓名：{{data.VIPUser.xingming}}</li>
              <li>手机：{{data.VIPUser.phone}}</li>
              <li>门店：{{data.VIPUser.fenpeimendianName}}</li>
              <li>范围：{{data.VIPUser.huiyuanfanwei==1?"连锁":"单店"}}</li>
              <li>通知：{{data.VIPUser.tongzhileixing==1?"短信":data.VIPUser.tongzhileixing==2?"公众号":"短信+公众号"}}</li>
              <li>等级：{{data.VIPUser.VIPLVName}}</li>
              <li>性别：{{data.VIPUser.xingbie}}</li>
            </ul>
            <ul>
              <li>余额：{{data.VIPUser.yue+data.VIPUser.zengsongyue}}（{{data.VIPUser.yue+"+"+data.VIPUser.zengsongyue}}）</li>
              <li>积分：{{data.VIPUser.jifen}}</li>
              <li>次数：</li>
              <li class="cishu"><p v-for="cs in data.VIPUser.cishu" :key="cs.id">{{cs.xiangmuName}} {{cs.num}} 次 有效期至{{getDate(cs.youxiaoqi)}}</p></li>
            </ul>
          </div>
        </div>
        <div>
          <div class="detail_b_1" v-if="$store.state.User.daoqishijian>=0"><a href="javascript:void(0)" @click="getTCrecod">查看提成</a></div>
          <div class="detail_b_2" v-if="data.TC.length">
            <table>
              <thead>
                <tr>
                  <th>员工</th>
                  <th>类型</th>
                  <th style="text-align:left;">项目</th>
                  <th>角色</th>
                  <th>数量</th>
                  <th>总金额</th>
                  <th>实收</th>
                  <th>提成</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in data.TC" :key="item.id">
                  <td>{{item.xingming}}</td>
                  <td>{{item.itemleixing==1?'商品':item.itemleixing==2?"项目":'充值'}}</td>
                  <td style="text-align:left;">{{item.itemName}}</td>
                  <td>{{item.title}}</td>
                  <td>{{item.num}}</td>
                  <td>{{item.shijia}}</td>
                  <td>{{item.shishou}}</td>
                  <td>{{item.ticheng}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      

    </div>
  </div>
</template>

<script>
import axios from '@/http/axios'
import {useRouter,useRoute} from "vue-router"
import {reactive} from "vue"
import {inportFun} from '@/components/Mesg/mesgshow'
import moment from 'moment'
export default {
  name: 'storeManagement',
  setup(){
    let data=reactive({
      VIPUser:{},
      TC:[]
    })
    const router=useRouter()
    const route=useRoute()
    const{CZJL,XFJL,UserId,guadan,guadanId}=route.query
    const getDate=(date)=>{
      if(date) return moment(date).format("YYYY-MM-DD")
      else return ''
    }
    const goback =()=>{
      router.back()
    }
    const getVIPUser =()=>{
       axios.post('/VIP/getVIPUserById',{
          id:UserId,
        }).then((res) => {
          if(res.code==5000){
            data.VIPUser=res.data
          }else inportFun({content: res.msg,errOrSuc: 'err'})
        })
    }
    const getTCrecod =()=>{
       axios.post('/record/getTCById',{
          xId:XFJL,
          cId:CZJL,
        }).then((res) => {
          if(res.code==5000){
            data.TC=res.data
          }else inportFun({content: res.msg,errOrSuc: 'err'})
        })
    }
    return{
      CZJL,
      XFJL,
      UserId,
      goback,
      data,
      guadan,
      guadanId,
      getDate,
      getVIPUser,
      getTCrecod,
    }
  },
 }
</script>
